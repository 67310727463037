import React, { useState } from "react"
import { useWindowSize } from "react-use"
import { Link, navigate } from "gatsby"
import { ToastContainer, toast } from "react-toastify"

import Seo from "../../components/seo.js"
import { TextInput } from "../../components/UserProfileFormBlocks"
import Tali from "../../components/Tali/index.js"
import Content from "../../components/content.js"

import { SignInStudentLayout } from "../../styles/common.js"

import { updateCurrentStudentData } from "../../services/helpers.js"

const GamesTestPage = () => {
  const isBrowser = typeof window !== "undefined" // check if executing instance is the browser or the building process
  const { height } = useWindowSize()
  const [loginCredentials, setLoginCredentials] = useState({})

  function ValidateCode(login) {
    return login?.length === 6
  }

  const handleChange = ({ target: { name, value } }) => {
    setLoginCredentials((loginCredentials) => ({
      ...loginCredentials,
      [name]: value,
    }))
    // TODO: do some input-validation here
  }

  const checkStudentData = async (loginCode) => {
    try {
      console.log("update student")
      const updateSuccessResult = await updateCurrentStudentData(loginCode)
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        window.localStorage.removeItem("jwt_student")
      } else if (updateSuccessResult.success && isBrowser) {
        navigate("/gamesTest/selection")
      }
    } catch (error) {
      toast.error(`Failed to login: ${error.message}`)
      if (isBrowser) window.localStorage.removeItem("jwt_student")
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const login = loginCredentials.code?.trim()

    if (ValidateCode(login)) {
      await checkStudentData(login)
    } else {
      toast.error("You must enter 6 letters.")
    }
  }

  return (
    <>
      <ToastContainer theme="colored" autoClose={2000} />
      <Seo title="Schüler Anmeldung" />
      <Content height={height}>
        <SignInStudentLayout as="form">
          <Tali
            size={1.5}
            mood="happy"
            position="top"
            float={false}
            messageStyle="glow"
          >
            <p className="tali-question">
              Hallo! Bist du ein Kind?
              <br />
              Dann gib bitte hier deinen Code ein.
            </p>
          </Tali>
          <TextInput
            handleChange={handleChange}
            id="code"
            name="code"
            label="code"
            required
            placeholder="Dein Code"
            type="text"
          />
          <nav>
            <Link className="button button--medium" to="/">
              Zurück
            </Link>
            <button
              style={{ marginLeft: "1rem" }}
              onClick={handleSubmit}
              className="continue-button button button--medium"
            >
              Weiter
            </button>
          </nav>
        </SignInStudentLayout>
      </Content>
    </>
  )
}

export default GamesTestPage
